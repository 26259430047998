exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-blog-post-js": () => import("./../../../src/pages/blog/blog-post.js" /* webpackChunkName: "component---src-pages-blog-blog-post-js" */),
  "component---src-pages-blog-blog-post-js-content-file-path-blog-architecture-decision-records-index-md": () => import("./../../../src/pages/blog/blog-post.js?__contentFilePath=/opt/build/repo/blog/architecture-decision-records/index.md" /* webpackChunkName: "component---src-pages-blog-blog-post-js-content-file-path-blog-architecture-decision-records-index-md" */),
  "component---src-pages-blog-blog-post-js-content-file-path-blog-choose-dependencies-index-md": () => import("./../../../src/pages/blog/blog-post.js?__contentFilePath=/opt/build/repo/blog/choose-dependencies/index.md" /* webpackChunkName: "component---src-pages-blog-blog-post-js-content-file-path-blog-choose-dependencies-index-md" */),
  "component---src-pages-blog-blog-post-js-content-file-path-blog-cqrs-index-md": () => import("./../../../src/pages/blog/blog-post.js?__contentFilePath=/opt/build/repo/blog/cqrs/index.md" /* webpackChunkName: "component---src-pages-blog-blog-post-js-content-file-path-blog-cqrs-index-md" */),
  "component---src-pages-blog-blog-post-js-content-file-path-blog-extreme-programming-index-md": () => import("./../../../src/pages/blog/blog-post.js?__contentFilePath=/opt/build/repo/blog/extreme-programming/index.md" /* webpackChunkName: "component---src-pages-blog-blog-post-js-content-file-path-blog-extreme-programming-index-md" */),
  "component---src-pages-blog-blog-post-js-content-file-path-blog-mob-programming-index-md": () => import("./../../../src/pages/blog/blog-post.js?__contentFilePath=/opt/build/repo/blog/mob-programming/index.md" /* webpackChunkName: "component---src-pages-blog-blog-post-js-content-file-path-blog-mob-programming-index-md" */),
  "component---src-pages-blog-blog-post-js-content-file-path-blog-principles-and-practices-index-md": () => import("./../../../src/pages/blog/blog-post.js?__contentFilePath=/opt/build/repo/blog/principles-and-practices/index.md" /* webpackChunkName: "component---src-pages-blog-blog-post-js-content-file-path-blog-principles-and-practices-index-md" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

